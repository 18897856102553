import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  loading: false,
};
const notificationSlice = createSlice({
  name: "notificationService",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getAllNotification: (state, { payload }) => {
      state.loading = false;
      state.notifications = payload;
    },
  },
});
const notificationReducer = notificationSlice.reducer;
export default notificationReducer;

export const { getLoading, getAllNotification } = notificationSlice.actions;
