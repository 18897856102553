import React from "react";
import store from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import MainRoutes from "./routes";
import { persistStore } from "redux-persist";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-alice-carousel/lib/alice-carousel.css";
import 'reactflow/dist/style.css';

import './components/Header/HeaderSingup.css'
import './components/Header/HeaderLanding.css'
import './pages/Map/style.css'
import './pages/Map/MapComponent.css'
import "./pages/Map/indexModal.css"
import "./pages/Catalogue/Catalogue.css";
import './pages/Catalogue/animation.css'
import './pages/IslandProfile/IslandProfile.css'
import './pages/Island/Island.css'
import './pages/Directory/Directory.css'
import './pages/Island/IslandAnimation.css'
import './pages/Profile/Profile.css'
import './pages/SignUp/SignUp.css'
import './pages/Login/Login.css'
import './pages/BrainSection/BrainSection.css'
import './pages/IslandProfile/IslandProfile.css'
import './assets/styles/app.scss'
import "./App.css";

let persistor = persistStore(store);

const App = (props) => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PayPalScriptProvider
          options={{
            "client-id":
              "AfBxsnahdMFzYX6t2ZIg5G-DbbinB7enczOp74SDMSISoKJAet7decL17YHGCHBIQvRNhAcXryM6qP_z",
            currency: "USD",
            "disable-funding": ["credit", "card"],
          }}
        >
          <MainRoutes typeWriterSound={props.typeWriterSound} />
        </PayPalScriptProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;