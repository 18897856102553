import authReducer from "../features/auth/auth.reducers";
import imageReducer from "../features/images/images.reducers";
import cartReducer from "../features/cart/cart.reducer";
import orderReducer from "../features/order/order.reducer";
import productReducer from "../features/product/product.reducer";
import dictioanaryReducer from "../features/dictionary/dictionary.reducer";
import notificationReducer from "../features/notification/notification.reducer";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducers = combineReducers({
  auth: authReducer,
  image: imageReducer,
  cart: cartReducer,
  notifications: notificationReducer,
  order: orderReducer,
  product: productReducer,
  dictionary:dictioanaryReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
