import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success: false,
  error: false,
  loading: false,
  uploadedImage: "",
  uploadedVid: "",
};
const uploadSlice = createSlice({
  name: "uploadService",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    uploadSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.uploadedImage = payload;
    },
    getEmpty: (state) => {
      state.loading = false;
      state.uploadedVid = "";
      state.uploadedImage = "";
    },
    uploadFailed: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
    },
    videoUploaded: (state, { payload }) => {
      state.uploadedVid = payload;
      state.loading = false;
    },
  },
});
const authReducer = uploadSlice.reducer;
export default authReducer;

export const {
  getLoading,
  uploadFailed,
  uploadSuccess,
  getEmpty,
  videoUploaded,
} = uploadSlice.actions;
