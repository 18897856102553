import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false, saved: false,
    dictionary: [],
    suggestions:[],
    message:""
};
const dictionarySlice = createSlice({
    name: "dictionaryService",
    initialState,
    reducers: {
        getLoading: (state) => {
            state.loading = true;
            state.message = "";
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        getdictionary: (state, { payload }) => {
            state.dictionary = payload;
            state.loading = false;
        },
        getSuggestions: (state, { payload }) => {
            state.suggestions = payload;
            state.loading = false;

        },
        setMessage: (state, { payload }) => {
            state.message = payload;
            state.loading = false;

        },
    },
});
const dictionaryReducer = dictionarySlice.reducer;
export default dictionaryReducer;

export const { getLoading,getSuggestions,setMessage, getdictionary, stopLoading } = dictionarySlice.actions;
