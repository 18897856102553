import logo1 from "../assets/images/logo1.png";
import logo2 from "../assets/images/logo2.png";
import logo3 from "../assets/images/logo3.png";
import treasureChest from "../assets/images/treasurechest.png";
import animation from "../assets/animation/emoji2.gif";
import suitcaseanimation from "../assets/video/case_3_5.mp4";
import keyboardeffect from "../assets/audio/keyboardeffect.mp3";
import loader from "../assets/video/loader.mp4";
import map_logo_right from "../assets/images/map_logo_right.png";
import sun from "../assets/images/sun.png";
import lungs from "../assets/images/lungs.png";
import heart from "../assets/images/heart.png";
import saturn from "../assets/images/Saturn.png";
import text from "../assets/images/text.png";
import downloadpapers from "../assets/images/downloadpapers.svg";
// WEB IMPORTS ISLAND STARTS
import Directory from "../assets/images/Directory.png";
import map_bottom_island from "../assets/images/map_bottom.png";
import locked_island from "../assets/images/locked_island.png";
import locked_island_text from "../assets/images/locked_island_text.png";
import default_heading from "../assets/images/default_heading.png";
import chain from "../assets/images/chain.png";
import chain_1 from "../assets/images/chain_1.png";
import right_diagnol_chain from "../assets/images/right_diagnol_chain.png";
import right_diagnol_chain_1 from "../assets/images/right_diagnol_chain _1.png";
import spine from "../assets/images/spine.png";

// MOBILE IMPORTS MAPS STARTS
import Saturn from "../assets/images/Saturn.png";
import right_top_logo from "../assets/images/right_top_logo.png";
// LETTER IMPORTS
import checkmark from "../assets/images/letter/checkmark.svg";
import X from "../assets/images/letter/X.svg";
import setting_heading from "../assets/images/settings_heading.png";
// import directoryheading from "../assets/images/directory_heading.png";

// CATALOGUE
import heading_catalogue from "../assets/images/Catalogue/heading.png";
import lock_catalogue1 from "../assets/images/Catalogue/lock 1.png";
import fb_logo from "../assets/images/Catalogue/fb_logo.svg";
import insta_logo from "../assets/images/Catalogue/insta_logo.svg";
import twitter_logo from "../assets/images/Catalogue/twitter_logo.svg";
import whatsapp_logo from "../assets/images/Catalogue/whatsapp_logo.svg";
import share from "../assets/images/Catalogue/Share.svg";
import folder from "../assets/images/Catalogue/folder.svg";

// FILE ISLAND
import report_compiling from "../assets/audio/report_compiling.mp3";
import open_file from "../assets/audio/open_file.mp3";
import update_file from "../assets/audio/update_file.mp3";
import retrieve_file from "../assets/audio/retrieve_file.mp3";
import map_svg from "./images/world_clear2.png";
import blackforest from "./images/Black_forest.png";
import cross from "../assets/images/cross.png";
import correct from "../assets/images/correct_new.png";
import retrieveFileVideo from "../assets/video/uploadfile.mp4"
import uploadFileVideo from "../assets/video/retrievefile.mp4"
import close_icon from "../assets/images/close_icon.svg";
import loadingGear from "../assets/images/loadinggear.gif"
import dialupsound from "../assets/audio/dialupsound.mp3"

// Map View Imports
import worldIcon from "../assets/images/map_switcher/SVG-Assets-Icons/world_icon.svg";
import gear from "../assets/images/gear.svg";
import arrowUp from "../assets/images/map_switcher/SVG-Assets-Icons/arrow_up.svg";
import arrowDown from "../assets/images/map_switcher/SVG-Assets-Icons/arrow_down.svg";
import cableIcon from "../assets/images/map_switcher/SVG-Assets-Icons/cable_icon.png";
import volcanoIcon from "../assets/images/map_switcher/SVG-Assets-Icons/volcano_icon.svg";
import starsIcon from "../assets/images/map_switcher/SVG-Assets-Icons/stars_icon.svg";
import dragonIcon from "../assets/images/map_switcher/SVG-Assets-Icons/dragon_icon.svg";
import minusIcon from "../assets/images/map_switcher/SVG-Assets-Icons/minus_icon.svg";
import plusIcon from "../assets/images/map_switcher/SVG-Assets-Icons/plus_icon.svg";
import roundedSky from "../assets/images/map_switcher/SVG-Assets-Icons/rounded_sky.png";
import underNeathImage from "../assets/images/map_switcher/SVG-Assets-Icons/Underworld Map without the Sky.png";
import worldMapTransition from "../assets/images/map_switcher/SVG-Assets-Icons/new_transition_screens/world_map_transition.jpg";
import cableMapTransition from "../assets/images/map_switcher/SVG-Assets-Icons/new_transition_screens/cable_map_transition.jpg";
import underNeathMapTransition from "../assets/images/map_switcher/SVG-Assets-Icons/new_transition_screens/under_neath_map_transition.jpg";
import starMapTransition from "../assets/images/map_switcher/SVG-Assets-Icons/new_transition_screens/star_map_transition.jpg";
import spaceMapTransition from "../assets/images/map_switcher/SVG-Assets-Icons/new_transition_screens/space_map_transition.jpg";
import volcanoMapTransition from "../assets/images/map_switcher/SVG-Assets-Icons/new_transition_screens/volcano_map_transition.jpg";
import cableMapTitleTrans from "../assets/images/map_switcher/SVG-Assets-Icons/Cable_Map title _Transparent.png";
import blackForest from './images/map_switcher/black_forest 1.png';
import islandBaseMap from './images/island_base_map.png';
import ExitIcon from "../assets/images/exitIcon.png"
import skeleton from "../assets/images/realitybox.png"
import bilboard1 from "../assets/images/bilboard1.png"
import bilboard2 from "../assets/images/bilboard2.png"
import giphy from "../assets/images/giphy.gif"
import settings from "../assets/images/settings.png"
import red_arrow from "../assets/images/red_arrow.png"
import book1 from "../assets/images/book1.png"
import CNS from "../assets/images/CNS.png"
import PNS from "../assets/images/PNS.png"

export const Images = {
  giphy,
  red_arrow,
  settings,
  bilboard2,bilboard1,
  ExitIcon,
  loadingGear,
  uploadFileVideo,
  retrieveFileVideo,
  dialupsound,
  spine,
  setting_heading,
  cross,
  skeleton,
  correct,
  open_file,
  update_file,
  retrieve_file,
  report_compiling,
  right_top_logo,
  right_diagnol_chain_1,
  right_diagnol_chain,
  chain_1,
  chain,
  folder,
  checkmark,
  X,
  heading_catalogue,
  share,
  lock_catalogue1,
  fb_logo,
  map_svg,
  blackforest,
  twitter_logo,
  whatsapp_logo,
  insta_logo,
  loader,
  map_logo_right,
  logo1,
  logo2,
  logo3,
  animation,
  keyboardeffect,
  sun,
  saturn,
  lungs,
  heart,
  Saturn,
  map_bottom_island,
  text,
  Directory,
  locked_island,
  locked_island_text,
  default_heading,
  suitcaseanimation,
  downloadpapers,
  close_icon,
  blackForest,
  islandBaseMap,
  treasureChest,
  CNS,
  PNS,
  book1
};

export const mapImages = {
  worldIcon,
  arrowUp,
  arrowDown,
  cableIcon,
  volcanoIcon,
  starsIcon,
  dragonIcon,
  minusIcon,
  plusIcon,
  roundedSky,
  underNeathImage,
  starMapTransition,
  worldMapTransition,
  cableMapTransition,
  underNeathMapTransition,
  spaceMapTransition,
  volcanoMapTransition,
  cableMapTitleTrans,
  gear
}

