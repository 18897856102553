import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false, saved: false,
    usersArtifact: [],
    allorders: []
};
const orderSlice = createSlice({
    name: "orderService",
    initialState,
    reducers: {
        getLoading: (state) => {
            state.loading = true;
        },
        getAllordersArtifacts: (state, { payload }) => {
            state.allorders = payload
        },
        addOrderSuccess: (state) => {
            state.loading = false;
            state.saved = true;
            state.succefullorder = "success"
        },
        reset:(state)=>{
            state.succefullorder = ""
        },
        addOrderFailure: (state) => {
            state.loading = false;
            state.saved = false;
            state.succefullorder = "notsuccess"
        },
        getAllArtifacts: (state, { payload }) => {
            state.loading = false;
            state.usersArtifact = payload;
        },
        

    },
});
const orderReducer = orderSlice.reducer;
export default orderReducer;

export const {
    getLoading, addOrderSuccess, addOrderFailure, getAllArtifacts, reset,getAllordersArtifacts
} = orderSlice.actions;
