import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keepLoggedIn: false,
  userInfo: null,
  authenticated: false,
  loginError: "",
  successMessage: "",
  loading: false,
  registerError: "",
  registerSuccess: "",
  errorMessage: null,
  userId: null,
  is_verfied: false,
  saveislandLoading: false,
  userPreviewData: "",
  deactivated: false,
  deleted: false,
  users: [],
  pinedUpdates:[],
  protectedRoutes: {}
};
const authSlice = createSlice({
  name: "authService",
  initialState,
  reducers: {
    isdeactivated: (state) => {
      state.deactivated = true;
    },
    isdeleted: (state) => {
      state.deleted = true
    },
    emptyStattee: (state) => {
      state.deactivated = false;
      state.deleted = false
    },
    getLoading: (state) => {
      state.loading = true;
      state.registerError = "";
      state.registerSuccess = "";
    },
    loginSuccess: (state, { payload }) => {
      state.loading = false;
      state.keepLoggedIn = payload.isKeepLoggedIn;
      localStorage.setItem("token", payload.token);
      state.userInfo = payload.doc;
      state.authenticated = true;
      state.loginError = false;
      if (!payload.isKeepLoggedIn) {
        sessionStorage.setItem("token", payload.token);
      }
    },
    loginFailed: (state) => {
      state.loading = false;
      state.authenticated = false;
      state.loginError = true;
    },
    registerSuccess: (state, { payload }) => {
      sessionStorage.setItem("token", payload.token);
      localStorage.setItem("token", payload.token);
      state.authenticated = true;
      state.keepLoggedIn = false;
      state.registerError = "";
      state.registerSuccess = payload.message;
      state.userInfo = payload.data;
      state.loading = false;
    },
    islandSavedSuccess: (state, { payload }) => {
      state.authenticated = true;
      state.registerSuccess = payload.message;
    //   state.userInfo = payload.data;
      state.loading = false;
      state.saveislandLoading = false;
    },
    getAllUsers: (state, { payload }) => {
      state.users = payload;
    },
    islandSavedLoading: (state) => {
      state.saveislandLoading = true;
    },
    caseimagesupload: (state, { payload }) => {
      state.authenticated = true;
      state.userInfo = {
        ...state.userInfo,
        caseimages: payload.data.caseimages,
      };
      state.loading = false;
    },
    casevideosupload: (state, { payload }) => {
      state.authenticated = true;
      state.userInfo = {
        ...state.userInfo,
        casevideos: payload.data.casevideos,
      };
      state.loading = false;
    },
    casevideosmisc: (state, { payload }) => {
      state.authenticated = true;
      state.userInfo = {
        ...state.userInfo,
        casemiscellaneous: payload.data.casemiscellaneous,
      };
      state.loading = false;
    },
    caseTweets: (state, { payload }) => {
      state.authenticated = true;
      state.userInfo = {
        ...state.userInfo,
        casetweets: payload.data.casetweets,
      };
      state.loading = false;
    },
    caseArtifacts: (state, { payload }) => {
      state.authenticated = true;
      state.userInfo = {
        ...state.userInfo,
        caseartifacts: payload.data.caseartifacts,
      };
      state.loading = false;
    },
    registerFailure: (state, { payload }) => {
      state.loading = false;
      state.authenticated = false;
      state.registerSuccess = "";
      state.registerError = payload.data.message;
    },
    pinedUpdates:(state,{payload})=>{
      state.pinedUpdates=payload
    },
    getVerified: (state) => {
      state.loading = false;
      state.is_verfied = true;
    },
    getLoggedInUser: (state, { payload }) => {
      state.userInfo = payload.data;
      state.loading = false;
    },
    updateProtectedRoutes: (state, { payload }) => {
        const obj = {}
        payload.data.caseartifacts.forEach(item => {
            if (item?.artifcats?.product1?.name === "Single Charts") {
                obj.singleStarcharts = true
            }
            else if (item?.artifcats?.product1?.name === "Double Charts") {
                obj.doubleStarcharts = true
            }
        })
        state.protectedRoutes = obj
    },
    getPreviewData: (state, { payload }) => {
      state.userPreviewData = payload.data;
      state.loading = false;
    },
    uploadSuccess: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.data;
    },
    getLogout: (state) => {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      localStorage.clear();
      state.authenticated = false;
      state.keepLoggedIn = false;
      state.userInfo = null;
      state.is_verfied = false;
    }
  },
});
const authReducer = authSlice.reducer;
export default authReducer;

export const {
  getLoading,
  loginSuccess,
  updateProtectedRoutes,
  loginFailed,
  registerSuccess,
  registerFailure,
  getVerified,
  getLogout,
  uploadSuccess,
  getLoggedInUser,
  caseimagesupload,
  islandSavedSuccess,
  islandSavedLoading,
  casevideosupload,
  casevideosmisc,
  caseTweets,
  getPreviewData,
  getAllUsers,
  caseArtifacts,isdeactivated,isdeleted,emptyStattee,pinedUpdates
} = authSlice.actions;
