import axios from 'axios';
import {
  loginSuccess,
  loginFailed,
  getLoading,
  registerSuccess,
  registerFailure,
  getVerified,
  getLogout,
  getLoggedInUser,
  caseimagesupload,
  islandSavedLoading,
  islandSavedSuccess,
  casevideosupload,
  casevideosmisc,
  caseTweets,
  getPreviewData,
  getAllUsers,
  caseArtifacts,
  isdeactivated,
  isdeleted,
  emptyStattee,
} from './auth.reducers';
import Swal from 'sweetalert2';
import { baseUrl } from '../../constants';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const login = (body) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .post(`${baseUrl}/api/users/login`, body, config)
    .then((res) => {
      dispatch(loginSuccess(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: res.data.message,
      });
    })
    .catch((err) => {
      if (err.response) {
        let continue_btn = document.getElementById('continue-btn');
        continue_btn.disabled = false;
        continue_btn.innerHTML = 'Continue';
        dispatch(registerFailure(err.response));
      }
    });
};

export const verified = (body) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/verifyUser/${body}`, config)
    .then((res) => dispatch(getVerified(res.data)))
    .catch((err) => {
      if (err.response) {
        dispatch(loginFailed(err));
      }
    });
};

export const logout = () => async (dispatch) => {
  dispatch(getLogout());
};

export const register = (email, password, username) => async (dispatch) => {
  dispatch(getLoading());
  const body = {
    email,
    password,
    username,
  };
  await axios
    .post(`${baseUrl}/api/users/register`, body, config)
    .then((res) => {
      dispatch(registerSuccess(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Please check email.',
        timer: 2500,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch(registerFailure(err.response));
        Swal.fire({
          icon: 'error',
          position: 'center',
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    });
};

export const updateUser = (body) => async (dispatch) => {
  await axios
    .put(`${baseUrl}/api/users/update_user`, body, config)
    .then((res) => {
      dispatch(getLoggedInUser(res.data));
    })
    .catch((err) => {
      console.log('error in updating user data', err)
    });
};

export const updateProgress = (id, chapter, progress) => async (dispatch) => {
  await axios
    .put(`${baseUrl}/api/users/updateProgress/${id}`, { progress: {[chapter]: progress} }, config)
    .then((res) => {
      if (res.data.message === 'Progress updated successfully') {
        axios.get(`${baseUrl}/api/users/getUser/${id}`, config)
          .then((res) => {
            dispatch(getLoggedInUser(res.data));
          })
      }
    })
    .catch((err) => {
      console.log('error in updating user data', err)
    });
};

export const fetchUser = (id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .get(`${baseUrl}/api/users/getUser/${id}`, config)
    .then((res) => {
      dispatch(getLoggedInUser(res.data));
    })
    .catch((err) => { });
};

export const fetchUsers = (body) => async (dispatch) => {
  let url;
  if (body.filter === 'public') {
    url = `${baseUrl}/api/users?sort=${body.sort}&filter=${body.filter}`;
  } else if (body.filter === 'sameisland') {
    url = `${baseUrl}/api/users?sort=${body.sort}&filter=${body.filter}&island=${body.island}`;
  }

  dispatch(getLoading());
  await axios
    .get(url, body, config)
    .then((res) => {
      dispatch(getAllUsers(res.data.data));
    })
    .catch((err) => { });
};

export const getPinnedUpdates = (body) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .get(
      `${baseUrl}/api/users/getPinnedUpdates?sort=${body.sort}&filter=${body.filter}`,
      config
    )
    .then((res) => {
      dispatch(getAllUsers(res.data.data));
    })
    .catch((err) => { });
};
export const getArtifacts = () => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .get(
      `${baseUrl}/api/users/getArtifacts`,
      config
    )
    .then((res) => {
      dispatch(getAllUsers(res.data.data));
    })
    .catch((err) => { });
};
export const getCosmicCast = () => async (dispatch) => {
  dispatch(getLoading());
  await axios.get(`${baseUrl}/api/users/getCosmicCast`, config)
    .then((res) => { dispatch(getAllUsers(res.data.data)); })
    .catch((err) => { });
};

export const fetchPreview = (id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .get(`${baseUrl}/api/users/getPriviews/${id}`, config)
    .then((res) => {
      dispatch(getPreviewData(res.data));
    })
    .catch((err) => { });
};

export const uploadProfileImage = (image, id) => async (dispatch) => {
  dispatch(getLoading());
  const body = { image };
  await axios
    .put(`${baseUrl}/api/users/uploadImage/${id}`, body, {
      'Content-Type': 'multipart/form-data',
    })
    .then((res) => {
      dispatch(registerSuccess(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Profile image updated.',
        timer: 2500,
      });
    });
};

export const uploadCaptionsandStoriesTweets = (_body, id) => async (dispatch) => {
  let body = { casetweets: _body };
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/updateCaptionAndStoryTweets/${id}`, body, config)
    .then((res) => {
      dispatch(caseTweets(res.data));
      dispatch(fetchUser(id));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const updateCaptionAndStoryArtifacts =
  (_body, id) => async (dispatch) => {
    dispatch(getLoading());
    await axios
      .put(`${baseUrl}/api/users/updateCaptionAndStoryArtifacts/${id}`, _body, config)
      .then((res) => {
        dispatch(caseArtifacts(res.data));
        Swal.fire({
          icon: 'success',
          position: 'center',
          title: 'Record updated.',
          timer: 2500,
        });
      });
  };

export const ImageIsPreview = (_body, id) => async (dispatch) => {
  let body = { caseimage_id: _body };
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/imageIsPreview/${id}`, body, config)
    .then((res) => {
      dispatch(caseimagesupload(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const videoIsPreview = (_body, id) => async (dispatch) => {
  let body = { caseimage_id: _body };
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/videoIsPreview/${id}`, body, config)
    .then((res) => {
      dispatch(casevideosupload(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const stickyIsPreview = (_body, id) => async (dispatch) => {
  let body = { caseimage_id: _body };
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/stickyIsPreview/${id}`, body, config)
    .then((res) => {
      dispatch(caseTweets(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const artifactIsPreview = (_body, id) => async (dispatch) => {
  let body = { caseimage_id: _body };
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/artifactIsPreview/${id}`, body, config)
    .then((res) => {
      dispatch(caseArtifacts(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const imageEditUpload = (_body, caseid, id) => async (dispatch) => {
  let body = _body;
  dispatch(getLoading());
  await axios
    .post(`${baseUrl}/api/upload/uploadFile`, body, config)
    .then(async (res) => {
      let data = { image: res?.data?.locationArray[0], caseimage_id: caseid };
      await axios
        .put(`${baseUrl}/api/users/imageEditUpload/${id}`, data, config)
        .then((res) => {
          dispatch(caseimagesupload(res.data));
          Swal.fire({
            icon: 'success',
            position: 'center',
            title: 'Record updated.',
            timer: 2500,
          });
        });
    });
};

export const videoEditUpload = (_body, caseid, id) => async (dispatch) => {
  let body = _body;
  dispatch(getLoading());
  await axios
    .post(`${baseUrl}/api/upload/uploadFile`, body, config)
    .then(async (res) => {
      let data = { image: res?.data?.locationArray[0], caseimage_id: caseid };
      await axios
        .put(`${baseUrl}/api/users/videoEditUpload/${id}`, data, config)
        .then((res) => {
          dispatch(casevideosupload(res.data));
          Swal.fire({
            icon: 'success',
            position: 'center',
            title: 'Record updated.',
            timer: 2500,
          });
        });
    });
};

export const uploadCaptionsandStories = (body, id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/updateCaptionAndStory/${id}`, body, config)
    .then((res) => {
      dispatch(caseimagesupload(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const deleteCaptionsandStories = (body, id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/deleteCaptionAndStory/${id}`, body, config)
    .then((res) => {
      dispatch(caseimagesupload(res.data));
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        position: 'center',
        title: 'Something went wrong! try later.',
        timer: 2500,
      });
    });
};

export const deleteCaptionsandStoriesVideo = (body, id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/deleteCaptionsandStoriesVideo/${id}`, body, config)
    .then((res) => {
      dispatch(casevideosupload(res.data));
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        position: 'center',
        title: 'Something went wrong! try later.',
        timer: 2500,
      });
    });
};

export const deleteCaptionsandStoriesMisc = (body, id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/deleteCaptionsandStoriesMisc/${id}`, body, config)
    .then((res) => {
      dispatch(casevideosmisc(res.data));
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        position: 'center',
        title: 'Something went wrong! try later.',
        timer: 2500,
      });
    });
};

export const deleteCaptionsandStoriesTweets =
  (body, id) => async (dispatch) => {
    dispatch(getLoading());
    await axios
      .put(`${baseUrl}/api/users/deleteCaptionsandStoriesTweets/${id}`, body, config)
      .then((res) => {
        dispatch(caseTweets(res.data));
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          position: 'center',
          title: 'Something went wrong! try later.',
          timer: 2500,
        });
      });
  };

export const uploadCaptionsandStoriesVideo = (body, id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/updateCaptionAndStoryVideos/${id}`, body, config)
    .then((res) => {
      dispatch(casevideosupload(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const uploadCaptionsandStoriesMisc = (body, id) => async (dispatch) => {
  dispatch(getLoading());
  await axios
    .put(`${baseUrl}/api/users/updateCaptionAndStoryMisc/${id}`, body, config)
    .then((res) => {
      dispatch(casevideosmisc(res.data));
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated.',
        timer: 2500,
      });
    });
};

export const uploadCaseData = (_body, id, value) => async (dispatch) => {
  dispatch(getLoading());
  let att_name =
    value === 'videos'
      ? 'casevideos'
      : value === 'images'
        ? 'caseimages'
        : 'casemiscellaneous';
  const tempbody = [];
  for (var i = 0; i < _body.length; i++) {
    tempbody.push({ image: _body[i] });
  }
  const body = {};
  body[att_name] = tempbody;
  await axios.put(`${baseUrl}/api/users/savecase/${id}`, body).then((res) => {
    dispatch(registerSuccess(res.data));
    Swal.fire({
      icon: 'success',
      position: 'center',
      title: 'Record updated.',
      timer: 2500,
    });
  });
};

export const updateCaseVideos = (_body, id, value) => async (dispatch) => {
  dispatch(getLoading());
  let att_name =
    value === 'videos'
      ? 'casevideos'
      : value === 'images'
        ? 'caseimages'
        : 'casemiscellaneous';
  const tempbody = [];
  for (var i = 0; i < _body.length; i++) {
    tempbody.push({ video: _body[i] });
  }
  const body = {};
  body[att_name] = tempbody;
  await axios.put(`${baseUrl}/api/users/savecaseVideo/${id}`, body).then((res) => {
    dispatch(registerSuccess(res.data));
    Swal.fire({
      icon: 'success',
      position: 'center',
      title: 'Record updated.',
      timer: 2500,
    });
  });
};

export const updateCaseMisc = (_body, id, value) => async (dispatch) => {
  dispatch(getLoading());
  let att_name =
    value === 'videos'
      ? 'casevideos'
      : value === 'images'
        ? 'caseimages'
        : 'casemiscellaneous';
  const tempbody = [];
  for (var i = 0; i < _body.length; i++) {
    tempbody.push({ file: _body[i] });
  }
  const body = {};
  body[att_name] = tempbody;
  await axios.put(`${baseUrl}/api/users/savecaseMisc/${id}`, body).then((res) => {
    dispatch(registerSuccess(res.data));
    Swal.fire({
      icon: 'success',
      position: 'center',
      title: 'Record updated.',
      timer: 2500,
    });
  });
};

export const uploadIslandImage =
  (image, image2, id, lastAction) => async (dispatch) => {
    dispatch(islandSavedLoading());
    const body = {
      saved_island: image,
      saved_island_deadversion: image2,
      lastAction: lastAction,
    };
    await axios
      .put(`${baseUrl}/api/users/saveIsland/${id}`, body, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        dispatch(islandSavedSuccess(res.data));
      });
  };

export const updateCasePrivacy = (body) => async (dispatch) => {
  await axios
    .put(`${baseUrl}/api/users/updatePrivacySettings/${body.id}`, body)
    .then((res) => {
      if (body.query === 'caseartifacts') {
        dispatch(caseArtifacts(res.data));
      } else if (body.query === 'casevideos') {
        dispatch(casevideosupload(res.data));
      } else if (body.query === 'casemisc') {
        dispatch(casevideosmisc(res.data));
      } else if (body.query === 'caseimage') {
        dispatch(caseimagesupload(res.data));
      }
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: 'Record updated successfully.',
        timer: 2500,
      });
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        position: 'center',
        title: 'Something went wrong! try later.',
        timer: 2500,
      });
    });
};

export const deactivateAccount = (body) => async (dispatch) => {
  await axios
    .put(`${baseUrl}/api/users/deactivedeleteacc/${body.id}`, body)
    .then((res) => {
      if (body.status === 'deactivate') {
        dispatch(isdeactivated());
        dispatch(getLogout());
      } else if (body.status === 'deleted') {
        dispatch(isdeleted());
        dispatch(getLogout());
      } else if (body.status === 'lock') {
        dispatch(fetchUser(body.id));
      }
      Swal.fire({
        icon: 'success',
        position: 'center',
        title: body.msg,
        timer: 2500,
      });
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        position: 'center',
        title: 'Something went wrong! try later.',
        timer: 2500,
      });
    });
};
export const emptyState = () => async (dispatch) => {
  dispatch(emptyStattee());
};
