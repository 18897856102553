import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allcollection:[], loading: false, saved: false, island_bodies: [], trees_of_life: [], model_modems: [], spinning_cups: [], coming_back_to_live: [], collection: [], hard_copy_artifacts: [], star_charts: [], fingerprints: [], signal: [], tdIsland: [], chapters: [], portal: [], inter_dimensional: [], courses: []
};
const productSlice = createSlice({
    name: "productService",
    initialState,
    reducers: {
        getLoading: (state) => {
            state.loading = true;
        },
        getisland_bodies: (state, { payload }) => {
            state.island_bodies = payload;
        },
        gettrees_of_life: (state, { payload }) => {
            state.trees_of_life = payload;
        },
        getmodel_modems: (state, { payload }) => {
            state.model_modems = payload;
        },
        get_spinning_cups: (state, { payload }) => {
            state.spinning_cups = payload;
        },
        get_coming_back_to_live: (state, { payload }) => {
            state.coming_back_to_live = payload;
        },
        get_hard_copy_artifacts: (state, { payload }) => {
            state.hard_copy_artifacts = payload;
        },
        get_star_charts: (state, { payload }) => {
            state.star_charts = payload;
        },
        get_fingerprints: (state, { payload }) => {
            state.fingerprints = payload;
        },
        get_signal: (state, { payload }) => {
            state.signal = payload;
        },
        get_skeleton: (state, { payload }) => {
            state.skeleton = payload;
        },
        get_3dIsland: (state, { payload }) => {
            state.tdIsland = payload;
        },
        get_chapters: (state, { payload }) => {
            state.chapters = payload;
        },
        get_portal: (state, { payload }) => {
            state.portal = payload;
        },
        get_inter_dimensional: (state, { payload }) => {
            state.inter_dimensional = payload;
        },
        get_courses: (state, { payload }) => {
            state.courses = payload;
        },
        getCollection: (state, { payload }) => {
            state.collection = payload
        },
        getFailure: (state) => {
            state.island_bodies = [];
            state.model_modems = [];
            state.collection = [];
            state.trees_of_life = [];
        },
        getcollection:(state,{payload})=>{
            state.allcollection = payload
        }
    },
});
const productReducer = productSlice.reducer;
export default productReducer;

export const { getcollection,getLoading, getisland_bodies, gettrees_of_life, getmodel_modems, get_spinning_cups, get_coming_back_to_live, getFailure,getCollection, get_hard_copy_artifacts, get_star_charts, get_fingerprints, get_signal, get_skeleton, get_3dIsland, get_chapters, get_portal, get_inter_dimensional, get_courses } = productSlice.actions;
